<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p v-t:_.statusMessage="missionData" class="main-msg" />
    <template v-slot:buttons>
      <button @click="route">{{ $t(`_.button`) }}</button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';

export default {
  name: 'MissionAchieveItem',
  components: { NotificationItem },
  lexicon: 'shared.notification.MISSION_ACHIEVE',
  props: {
    message: Specific,
  },
  computed: {
    /** @type {NotificationMissionData} */
    missionData() {
      return this.message.data;
    },
  },
  methods: {
    route() {
      this.routePath('/user/me/missions');
      this.$emit('read-item', this.message || {});
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

</style>
